import { useLanguage } from '~/hooks/useLanguage';
import { Number } from './Number';

export type PriceProps = {
  currency: string;
  children: number;
  maximumFractionDigits?: number;
}

export const Price = ( { children, currency, maximumFractionDigits = 2 } : PriceProps ) => {
  const language = useLanguage();

  return (
    <Number
      style={ 'currency' }
      locale={ language }
      currency={ currency }
      maximumFractionDigits={ maximumFractionDigits }
    >
      { children }
    </Number>
  )
}