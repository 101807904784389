type NumberProps = {
  children: number;
  locale?: Parameters<typeof Intl.NumberFormat>['0'];
} & Parameters<typeof Intl.NumberFormat>['1'];

export const Number = ( props: NumberProps ) => {
  const { children, locale } = props;

  try {
    return Intl.NumberFormat( locale, props ).format( isNaN( children ) ? 0 : children );
  } catch ( ex ) {
    return children;
  }
}
